var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    {
      style: { height: "100%" },
      attrs: {
        bordered: false,
        "body-style": { padding: "16px 0", height: "100%" }
      }
    },
    [
      _c(
        "div",
        { staticClass: "settings-info-main", class: _vm.device },
        [_c("router-view")],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }