//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mixinDevice } from '@/utils/mixin.js';

export default {
  mixins: [mixinDevice],
  data() {
    return {
      // horizontal  inline
      mode: 'inline',

      openKeys: [],
      selectedKeys: [],

      // cropper
      preview: {},
      option: {
        img: '/avatar2.jpg',
        info: true,
        size: 1,
        outputType: 'jpeg',
        canScale: false,
        autoCrop: true,
        autoCropWidth: 180,
        autoCropHeight: 180,
        fixedBox: true,
        fixed: true,
        fixedNumber: [1, 1]
      },
      menuList: [
        {
          key: '/settings/email',
          link: '/settings/email',
          name: 'Profile'
        },
        {
          key: '/settings/change-password',
          link: '/settings/change-password',
          name: 'Menu Change Password'
        },
        {
          key: '/settings/header-footer-setting',
          link: '/settings/header-footer-setting',
          name: 'Header & Footer Setting'
        }
      ]
    };
  },
  watch: {
    $route() {
      this.updateMenu();
    }
  },
  created() {
    this.updateMenu();
  },
  methods: {
    onOpenChange(openKeys) {
      this.openKeys = openKeys;
    },
    updateMenu() {
      const routes = this.$route.matched.concat();
      this.selectedKeys = [routes.pop().path];
    }
  }
};
